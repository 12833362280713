import React from 'react';
import BackgroundComponent from '../Components/bg';
import { Link } from 'react-router-dom';
import './styles.css';
const workshop= () => {
    return (
        <div className="min-h-screen relative">  
            <BackgroundComponent />
            <Link to="/">
                <button className="absolute  z-50 top-5 left-5 bg-white text-blue-500 rounded-lg shadow-lg p-2 hover:text-white border-2 border-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                   Home
                </button>
            </Link>
            <div className="absolute top-5 left-0 right-0 flex flex-col items-center">
        <div className="flex">
          <span className="block outlined-green text-4xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-7xl font-bold font-matura text-pink-500 whitespace-nowrap">
          Shop
          </span>
          
        </div> 
        <div className="inset-0 flex flex-col mt-10  pt-5 pb-10 bg-white mx-5 sm:mx-20 xl:mx-40 text-left ">
        <p1 className=" mx-5 pt-5">
        <strong> Donate → </strong>This Mayan Embodiment contains no advertising, and gives away an enormous amount of extremely valuable high vibration information for free. Crispy has dedicated well over 10,000 hours of his time to the creation of this site, and would greatly welcome any monetary energetic contribution you wish to make.
        </p1>
        <div className="flex justify-center w-full mt-4">
                    <a href="https://www.paypal.com/donate/?hosted_button_id=MQU7VN2MKHLC8" target="_blank" rel="noopener noreferrer">
                        <button type="button" className="text-white bg-blue-600 hover:text-white border-2 border-sky-500 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                          Donate here
                        </button>
                    </a>
        </div>

       <p1 className="mx-5 pt-10">
       
       * Item One:<strong> Thirteen Month Mayan Embodiment Calendar → $9.99 </strong>
This is a 300dpi PDF download that starts the month you purchase it and contains not only the following 12 months labeled with the images for Tone and Glyph of every day, as well as the Embodiment terminology, but it includes plenty of other explanatory graphical information, including every Key Code Word for every Tone and Glyph. 
 </p1>  
 <p1 className="mx-5">Sample :</p1>
 <img src="/example.png" alt="Calendar Sample" className='mx-5 border-2 border-gray-500' /> 
        
       
       
   
        <p1 className=" mx-5 pt-5">
        * Item Two:<strong> Core Self Reading → $13.20</strong> (See Readings page for details.)
        </p1>
        
       
       
        <p1 className=" mx-5 pt-5">
        * Item Three:<strong> Core Self Extended Mayan Embodiment Reading → $20.13 </strong>(See Readings page for details.)
        </p1>
        
        <p1 className=" mx-5 pt-5">
        * Item Four:<strong> Full Mayan Embodiment Soul Cartography Reading → </strong>Sliding Scale from <strong>$99.99 up to $260.</strong> (See Readings page for details.) 
        </p1>
        
        <p1 className=" mx-5 pt-5">
        * Item Five: <strong>Full Mayan Embodiment Combined Soul Cartography Reading → </strong>Sliding Scale from <strong>$520 up to $1320.</strong> (See Readings page for details.) 
        </p1>
        
        <p1 className=" mx-5 pt-5">
        * Item Six: <strong> Tarot Card Reading → $33.39 </strong> Crispy does face-to-face tarot throws in one of two styles. The first is a five-card pattern that he created many years before Mayan Embodiment began, consisting of the Significator, the “Right Move” or “Helping Person” card, a “Mystery” or “Unknown factor” card, the “Wrong Move” or “Unhelpful Person” card, and finally, the “Spiritual Implication” card. The other pattern is also five cards, but they follow the Mayan Embodiment system of the Empowerment Circle. These can also be done without live connection, but Crispy will take a video of himself turning over the cards, and give you a written description.
        </p1>
        
        
        
        <div className="flex justify-center w-full mt-4">
                    <a href="https://www.paypal.com/ncp/payment/NXBCTTBKRY4L8" target="_blank" rel="noopener noreferrer">
                        <button type="button" className="text-white bg-blue-600 hover:text-white border-2 border-sky-500 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                           Buy now
                        </button>
                    </a>
                </div>
        </div>
        </div>
        </div>
    );
};

export default workshop;